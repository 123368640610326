<template>
  <div class="tubes-table">
    <RecallInput/>
    <h4 id="steel-table">{{ selectedProductHeading }}</h4>
         <div class="row">
           <div class="col s12">
           <label>
             <input type="checkbox" v-model="showNotInStock"/>
             <span>показывать товары которых нет в наличии</span>
           </label>
           </div>
          <!-- <div class="switch col s2">
             <label>
               метры
               <input type="checkbox" v-model="switchUnits">
               <span class="lever"></span>
               тонны
             </label>
           </div> -->
           <!-- <div class="col s4"><input type="text" v-model.trim="search" placeholder="Поиск по товарам..."/></div> -->
         </div>
        <table class="striped centered">
        <thead>
          <tr>
              <th>Наименование</th>
              <th>Вес</th>
              <th>Марка стали</th>
              <th>Длина / Размер</th>
              <th>Цена за метр</th>
              <th>Метров</th>
              <th>Итог</th>
              <th class="add-cart-button">Добавить в корзину</th>
          </tr>
        </thead>

        <tbody v-for="p in productsToShow" :key="p.id">
          <tr>
            <td><i :class="foIcon[p.categoryId]"></i>{{`${p.name} ${p.side_scale} мм`}}</td>
            <td title="килограмм в одном метре">{{p.weight + ' кг'}}</td>
            <td>{{p.steel_grade}}</td>
            <td>{{p.size_of_one_piece + ' м'}}</td>
            <td>{{formatPrice(p.price, p.weight)}}</td>
            <td><QuantityInput :prod="p" :isItTones="switchUnits"/></td>
            <td>{{rowResult(p.quantity, p.price, p.weight)}}</td>
            <td class="add-cart-button">
              <button title="добавить в корзину" class="btn-floating btn-medium waves-effect waves-light teal darken-1" @click="toOrder(p.id, p.name, p.side_scale, p.price, p.weight, p.quantity, p.categoryId)">
                <i class="material-icons">shopping_cart</i></button></td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>
import cards from './cardProducts.json'
import RecallInput from '@/components/RecallInput.vue'
import QuantityInput from '@/components/QuantityInput.vue'
import axios from 'axios'

export default {
  name: 'Table',
  components: {
    RecallInput,
    QuantityInput
  },
  mounted () {
    axios
      .get('https://steelon.ru/api/v1/products')
      .then(response => {
        this.products = response.data
      })
      .catch(error => {
        console.log(error)
        this.apiErrors = true
      })
  },
  props: {

  },
  computed: {
    selectedProductHeading: function () {
      let filtered = cards.filter(o => o.id === this.$store.state.whatToShowInTable)
      return filtered[0].title
    },
    productsToShow: function () {
      let filtered = this.products.filter(o => o.categoryId === this.$store.state.whatToShowInTable && o.side_scale.toLowerCase().includes(this.search.toLowerCase()))
      if (this.showNotInStock === false) {
        return filtered.filter(o => o.in_stock)
      }
      return filtered
    }
  },
  methods: {
    formatPrice: function (price = 0, weight = 0) {
      if (!this.switchUnits) {
        let meteresInTonn = 1000 / weight
        price = price / meteresInTonn
      }
      if (price === 0) {
        return `нет в наличии`
      }
      return `${this.formatFinancial(price)} ₽`
    },
    rowResult: function (quantity = 0, price = 0, weight = 0) {
      if (quantity <= 0 || isNaN(quantity)) {
        return ' '
      }
      if (quantity > 5432) {
        return 'уточните по телефону'
      }
      if (!this.switchUnits) {
        quantity = quantity * weight / 1000
      }
      return this.formatFinancial(quantity * price) + ' ₽'
    },
    formatFinancial: function (n) {
      return Number.parseFloat(n).toFixed(2)
    },
    // eslint-disable-next-line
    toOrder: function (id, name, side_scale, price, weight, quantity, categoryId) {
      let units = ' м'
      if (this.switchUnits) {
        units = ' т'
      }
      if (quantity) {
        this.$store.commit('addToOrder', { id, name, side_scale, price, weight, quantity, units, categoryId })
        this.$store.commit('addToGlobalMessages', `${name} теперь в корзине`)
        setTimeout(() => this.$store.commit('clearGlobalMessages'), 1200)
      }
    }
  },
  data () {
    return {
      showNotInStock: false,
      switchUnits: false,
      products: [],
      apiErrors: false,
      search: '',
      foIcon: { 0: 'fo-product-pipe_rectangular',
        1: 'fo-product-corner',
        2: 'fo-product-pipe_round',
        3: 'fo-product-armature',
        4: 'fo-product-sheet' }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 2% 0 3%;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
th {
  text-align: left !important;
}
td {
  text-align: left !important;
}
.add-cart-button {
  text-align: center !important;
}
</style>
