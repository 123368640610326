/* eslint-disable */

// const tubes = require('./steel_hexagon.json')
const fs = require('fs')

let products = []

function convert (object, id) {
    for (let [k, v] of Object.entries(object)) {
        k = (k.split(' '))
        products.push({'id':id, 'name': k[0], 'category': k[0], 'side_scale': k[1], 'weight': v, 'price': 0})
    }
}
convert(tubes, 9)
console.log(products)



const path = './new/steel_hexagon.json',
buffer = JSON.stringify(products, '', 4)

fs.open(path, 'w', function(err, fd) {
    if (err) {
        throw 'error opening file: ' + err
    }

    fs.write(fd, buffer, 0, buffer.length, function(err) {
        if (err) throw 'error writing file: ' + err
        fs.close(fd, function() {
        console.log('file written')
        })
    })
})