<template>
  <div class="row">
    <div class="col offset-l1 offset-m3">
      <div class="card col l2 m4 s12" v-for="card in cards" :key="card.id">
        <div class="card-image waves-effect waves-block waves-light">
          <img class="activator" :src="require(`@/assets/${card.image}`)" />
        </div>
        <div class="card-content">
          <span class="card-title activator blue-grey-text text-darken-4">
            {{
            card.title
            }}
          </span>
          <button
            class="waves-effect waves-light btn-small blue-grey lighten-1"
            @click="change(card.id)"
            v-scroll-to="'#steel-table'"
          >Показать</button>
        </div>
        <div class="card-reveal">
          <span class="card-title grey-text text-darken-4">
            {{ card.title }}
            <i class="material-icons right">close</i>
          </span>
          <p>{{ card.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cards from './cardProducts.json'
export default {
  name: 'TubeCards',
  props: {},
  methods: {
    change (id) {
      this.$Progress.set(90)
      setTimeout(() => {
        this.$Progress.finish()
      }, 400)
      this.$store.commit('changeId', id)
    }
  },
  data () {
    return {
      cards
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  padding: 0 !important;
  margin-right: 0.5em;
  button {
    margin-top: 3em;
  }
}
</style>
