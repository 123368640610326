import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  supportCircular: true
})

export default new Vuex.Store({
  state: {
    whatToShowInTable: 0,
    order: [],
    globalMessages: []
  },
  mutations: {
    changeId (state, id) {
      state.whatToShowInTable = id
    },
    addToOrder (state, payload) {
      state.order.push(payload)
    },
    removeFromOrder (state, payload) {
      state.order.splice(payload, 1)
    },
    addToGlobalMessages (state, payload) {
      state.globalMessages.splice(0, 1, payload)
    },
    clearGlobalMessages (state) {
      state.globalMessages = []
    }
  },
  actions: {},
  plugins: [vuexLocal.plugin]
})
