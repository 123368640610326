<template>
  <div class="home">
    <TubeCards></TubeCards>
    <Table />
  </div>
</template>

<script>
// @ is an alias to /src
import Table from '@/components/Table.vue'
import TubeCards from '@/components/TubeCards.vue'

export default {
  name: 'home',
  components: {
    Table,
    TubeCards
  }
}
</script>
