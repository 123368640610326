import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueProgressBar from 'vue-progressbar'
import VueScrollTo from 'vue-scrollto'
import '../node_modules/materialize-css/sass/materialize_cut.scss'

Vue.config.productionTip = false

const progressOptions = {
  color: '#009688',
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.5s',
    opacity: '1.1s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

const scrollOptions = {
  container: 'body',
  duration: 500,
  easing: 'linear',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
}

Vue.use(VueProgressBar, progressOptions)
Vue.use(VueScrollTo, scrollOptions)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
