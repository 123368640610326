<template>
<transition name="fade" :duration="{ enter: 500, leave: 800 }">
<div id="toast-container">
<div class="toast message">
  {{globalMessages}}
</div>
</div>
</transition>
</template>

<script>
export default {
  name: 'Messages',
  props: ['globalMessages'],
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#toast-container {
  position: fixed;
  z-index: 1000;
  top: 85%;
  right: 1%;
}
.message {
  background-color: #00897b !important;
  max-width: 15em;
}

</style>
