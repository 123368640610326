<template>
  <div id="app">
  <nav>
    <div class="nav-wrapper blue-grey lighten-3">
      <a href="#" data-target="mobile-demo" class="sidenav-trigger" @click="toggleSideNavVision"><i class="material-icons">menu</i></a>
      <a class="brand-logo right"><i class="material-icons">email phone_android</i>mail@steelon.ru | +7 929 238 41 64</a>
      <ul id="nav-mobile" class="left hide-on-med-and-down">
        <li><router-link to="/">Главная</router-link></li>
        <li><router-link to="delivery">Доставка и Оплата</router-link></li>
        <li><router-link to="order">Корзина<span class="new badge" data-badge-caption="шт.">{{order.length}}</span></router-link></li>
      </ul>
    </div>
  </nav>
    <ul class="sidenav" id="mobile-demo" :style="[ sideNavIsOpened ? sideNavStyleOpened : sideNavStyleClosed ]">
        <li><router-link to="/">Главная</router-link></li>
        <li><router-link to="delivery">Доставка и Оплата</router-link></li>
        <li><router-link to="order">Корзина<span class="new badge" data-badge-caption="шт.">{{order.length}}</span></router-link></li>
        <li @click="toggleSideNavVision" class="mobile-sidenav__icon-text"><a><i class="tiny material-icons">close<i>Закрыть</i></i></a></li>
  </ul>
    <Messages v-if="globalMessages" :global-messages="globalMessages"></Messages>
    <router-view/>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import Messages from '@/components/Messages.vue'
export default {
  components: {
    Messages
  },
  computed: {
    globalMessages () {
      return this.$store.state.globalMessages[0]
    }
  },
  methods: {
    toggleSideNavVision () {
      this.sideNavIsOpened = !this.sideNavIsOpened
    }
  },
  data () {
    return {
      order: this.$store.state.order,
      sideNavIsOpened: false,
      sideNavStyleOpened: { transform: `translateX(0%)` },
      sideNavStyleClosed: { transform: 'translateX(-100%)' }
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
         local('MaterialIcons-Regular'),
         url('./assets/MaterialIcons-Regular.woff2') format('woff2');
    font-display: swap;
  }

 @font-face {
  font-family: 'fontello';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fontello.woff2') format('woff2');
  font-display: swap;
}

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;

    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
  .mobile-sidenav__icon-text {
    cursor: pointer;
    text-align: center;
  }
  .sidenav {
    text-align: left;
  }
nav {
  .brand-logo {
    font-size: 1.5rem !important;
  }
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      background-color: #aab8be;
    }
  }
  @media (min-width: 120px) and (max-width: 480px) {
    .brand-logo {
      font-size: 1em !important;
    }
   }
}
/* fontello tubes icons rules*/
  [class^="fo-"]:before, [class*=" fo-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .5em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased fos size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.fo-arrow-down:before { content: '\e800'; }
.fo-phone:before { content: '\e801'; }
.fo-arrow-up:before { content: '\e802'; }
.fo-carret-down:before { content: '\e803'; }
.fo-carret-up:before { content: '\e804'; }
.fo-calendar-1:before { content: '\e805'; }
.fo-gear:before { content: '\e806'; }
.fo-grid:before { content: '\e807'; }
.fo-list:before { content: '\e808'; }
.fo-logo:before { content: '\e809'; }
.fo-map:before { content: '\e80a'; }
.fo-plus:before { content: '\e80b'; }
.fo-search:before { content: '\e80c'; }
.fo-trash-1:before { content: '\e80d'; }
.fo-angle-left:before { content: '\e80e'; }
.fo-angle-right:before { content: '\e80f'; }
.fo-eye-closed:before { content: '\e810'; }
.fo-product-pipe_round:before { content: '\e811'; }
.fo-product-pipe_rectangular:before { content: '\e812'; }
.fo-close:before { content: '\e813'; }
.fo-doc:before { content: '\e814'; }
.fo-message:before { content: '\e815'; }
.fo-calculator:before { content: '\e816'; }
.fo-eye-opened:before { content: '\e817'; }
.fo-dot:before { content: '\e818'; }
.fo-right:before { content: '\e819'; }
.fo-left:before { content: '\e81a'; }
.fo-ok:before { content: '\e81b'; }
.fo-positions:before { content: '\e81c'; }
.fo-delivery:before { content: '\e81d'; }
.fo-product-sheet:before { content: '\e81e'; }
.fo-product-girder:before { content: '\e81f'; }
.fo-product-corner:before { content: '\e820'; }
.fo-product-hexagon:before { content: '\e821'; }
.fo-delivery-warn:before { content: '\e822'; }
.fo-warning:before { content: '\e823'; }
/* 'î £' */
.fo-call-24px:before { content: '\e824'; }
/* 'î ¤' */
.fo-person_add-24px:before { content: '\e825'; }
/* 'î ¥' */
.fo-reorder-24px:before { content: '\e826'; }
/* 'î ¦' */
.fo-shopping_cart-24px:before { content: '\e827'; }
/* 'î §' */
.fo-phone-1:before { content: '\e828'; }
/* 'î ¨' */
.fo-basket:before { content: '\e829'; }
/* 'î ©' */
.fo-calendar:before { content: '\e82a'; }
/* 'î ª' */
.fo-login:before { content: '\e82b'; }
/* 'î «' */
.fo-logout:before { content: '\e82c'; }
/* 'î ¬' */
.fo-product-wire:before { content: '\e82d'; }
/* 'î ­' */
.fo-product-wirerod:before { content: '\e82e'; }
/* 'î ®' */
.fo-product-strip:before { content: '\e82f'; }
/* 'î ¯' */
.fo-download:before { content: '\e830'; }
/* 'î °' */
.fo-product-armature:before { content: '\e831'; }
/* 'î ±' */
.fo-exit:before { content: '\e832'; }
/* 'î ²' */
.fo-product-channel:before { content: '\e833'; }
/* 'î ³' */
.fo-trash:before { content: '\e834'; }
/* 'î ´' */
.fo-pencil:before { content: '\e835'; }
/* 'î µ' */
.fo-product-square:before { content: '\e836'; }
/* 'î ¶' */
.fo-product-circle:before { content: '\e837'; }
/* 'î ·' */
.fo-spin:before { content: '\e838'; }
/* 'î ¸' */
.fo-product-sheet_pvl:before { content: '\e839'; }
/* 'î ¹' */
.fo-product-sheet_ribbed:before { content: '\e83a'; }
/* 'î º' */
.fo-phone-squared:before { content: '\e83b'; }
/* 'î »' */
.fo-menu:before { content: '\e83c'; }
/* 'î ¼' */
.fo-cart-plus:before { content: '\e83d'; }
/* 'î ½' */
.fo-cart-arrow-down-1:before { content: '\e83e'; }
/* 'î ¾' */
.fo-user:before { content: '\e83f'; }
/* 'î ¿' */
.fo-checkbox:before { content: '\e840'; }
/* 'î¡€' */
.fo-user-plus:before { content: '\e841'; }

.fo-home:before { content: '\e842'; }
/* 'î¡‚' */
.fo-attach:before { content: '\e843'; }
/* 'î¡ƒ' */
.fo-print:before { content: '\e844'; }
/* 'î¡„' */
.fo-mail:before { content: '\e845'; }
/* 'î¡…' */
.fo-product-cap:before { content: '\e846'; }
/* 'î¡†' */
.fo-glass:before { content: '\e847'; }
/* 'î¡‡' */
.fo-product-tap:before { content: '\e848'; }
/* 'î¡ˆ' */
.fo-product-junction:before { content: '\e849'; }
/* 'î¡‰' */
.fo-product-flange:before { content: '\e84a'; }
/* 'î¡Š' */
.fo-facebook:before { content: '\f09a'; }
/* 'ï‚š' */
.fo-youtube-play:before { content: '\f16a'; }
.fo-instagram:before { content: '\f16d'; }
.fo-vkontakte:before { content: '\f189'; }
.fo-file-pdf:before { content: '\f1c1'; }
.fo-file-word:before { content: '\f1c2'; }
.fo-file-excel:before { content: '\f1c3'; }
.fo-file-powerpoint:before { content: '\f1c4'; }
.fo-file-image:before { content: '\f1c5'; }
.fo-file-archive:before { content: '\f1c6'; }
.fo-user-secret:before { content: '\f21b'; }
.fo-user-times:before { content: '\f235'; }
.fo-odnoklassniki:before { content: '\f263'; }
</style>
