<template>
<div>
<input type="number" class="table-input-quantity" min="0" max="99999" v-model.number="prod.quantity" @keypress="preventToInputChars($event)"/>
</div>
</template>

<script>
export default {
  name: 'QuantityInput',
  props: ['prod', 'isItTones'],
  methods: {
    preventToInputChars: function (e) {
      if ((e.keyCode <= 48 && e.keyCode >= 57) || (e.keyCode <= 96 && e.keyCode >= 105 && e.keyCode !== 110)) {
        e.preventDefault()
      }
    }
  },
  watch: {
    'prod.quantity': function (val, oldVal) {
      let sliceFrom = 6
      if (this.isItTones) {
        sliceFrom = 5
      }
      let newValue = val.toString().slice(0, sliceFrom)
      newValue = parseFloat(newValue)
      this.prod.quantity = newValue
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-input-quantity {
  width: 5em !important;
}
</style>
